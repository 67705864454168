<template>
  <i class="arrow__left">
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.49188 7.29289C0.101357 7.68342 0.101356 8.31658 0.49188 8.7071L6.85584 15.0711C7.24637 15.4616 7.87953 15.4616 8.27006 15.0711C8.66058 14.6805 8.66058 14.0474 8.27006 13.6569L2.6132 8L8.27006 2.34314C8.66058 1.95262 8.66058 1.31946 8.27006 0.928931C7.87953 0.538407 7.24637 0.538407 6.85584 0.928931L0.49188 7.29289ZM22.1434 7L1.19899 7L1.19899 9L22.1434 9L22.1434 7Z" fill="#B4B4B4"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'ArrowLeftIcon'
}
</script>
