<template>
  <div class="lds-heart">
    <div>
      Загрузка...
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>
