<template>
  <div v-if="$store.state.showCancelSubscrModal" class="modal__wrapper">
    <div @click="closeModal" class="modal__back">
    </div>
    <div class="container">
      <div class="modal__inner form__container">
        <form v-if="!formIsSent" @submit.prevent class="modal__form">
          <div class="modal__inputs">
            <div class="modal__input">
              <label class="input__label" for="phone">Номер телефона</label>
              <input @input="form.phone.message = ''" v-model="form.phone.value" :class="{ error: form.phone.message}" class="input" id="phone" type="text" v-mask="'+7 (###) ###-##-##'" placeholder="+7 (___) ___-__-__">
              <small v-if="form.phone.message" class="form__error__text">{{ form.phone.message }}</small>
            </div>
          </div>

          <button v-if="!loading" @click="submitCancelSubscription" class="form__button btn" type="button">Отправить</button>
          <button v-else class="form__button" type="button">Загрузка...</button>
        </form>
        <div v-else class="success">
          <p>Ваш запрос отправлен!</p>
        </div>

        <a @click="closeModal" class="modal__close__btn">
          <CloseBtn/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CloseBtn from 'components/svg/Close.vue'

export default {
  name: 'CancelSubscription',
  data () {
    return {
      form: {
        phone: {
          value: '',
          message: ''
        }
      },
      loading: false,
      formIsSent: false,
      timeout: ''
    }
  },
  methods: {
    closeModal () {
      this.$store.state.showCancelSubscrModal = false
      this.formIsSent = false
      this.form.phone.message = ''
      clearTimeout(this.timeout)
    },
    submitCancelSubscription () {
      this.form.phone.message = this.form.phone.value.length !== 18 ? 'Введите номер телефона' : ''
      if (this.form.phone.value.length !== 18) {
        return 0
      }
      this.loading = true
      this.$store.dispatch('POST_CANCEL_SUBSCRIPTION', {
        number: this.form.phone.value
      })
        .then(response => {
          this.formIsSent = true
          this.loading = false
          Object.keys(this.form).forEach(item => {
            this.form[item].value = ''
          })
          this.timeout = setTimeout(() => {
            this.$store.state.showCancelSubscrModal = false
            this.formIsSent = false
          }, 2000)
        })
        .catch(err => {
          this.formIsSent = false
          this.loading = false
          console.log(err)
        })
    }
  },
  components: {
    CloseBtn
  }
}
</script>
