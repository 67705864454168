<template>
  <section class="about__children" v-if="about_children">
    <div class="container about__children__container">
      <div class="about__children__inner">
        <div class="welcome__achievements" v-if="donate_count || children_count">
          <div class="achievements__div title">
            Наши достижения
          </div>
          <div class="achievements__div" v-if="donate_count">
            <span class="achievements__money">{{donate_count.value}}</span>
            <span class="achievements__desc">{{donate_count.title}}</span>
          </div>
          <div class="achievements__div" v-if="children_count">
            <span class="achievements__number">{{children_count.value}}</span>
            <span class="achievements__desc">{{children_count.title}}</span>
          </div>
        </div>

        <div class="about__children__info">
          <h2 class="about__children__title">
            {{about_children.title}}
          </h2>
          <p class="about__children__text">
            {{about_children.value}}
          </p>
          <a href="#" class="more__link">Подробнее</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutChildren',
  computed: {
    about_children: function () {
      return this.$store.state.home_page?.page_texts?.about_children
    },
    donate_count: function () {
      return this.$store.state.home_page?.page_texts?.donate_count
    },
    children_count: function () {
      return this.$store.state.home_page?.page_texts?.children_count
    }
  }
}
</script>
