<template>
  <section class="donate section">
    <div class="container">
      <div class="donate__inner form__container">
        <h3 class="donate__title">
          <label for="donate">Пожертвовать сиротам</label>
        </h3>

        <form @submit.prevent="submitDonateForm" class="donate__form">
          <ul class="donate__value__list">
            <li>
              <button type="button" @click="chooseSum(100)">100&nbsp;₽</button>
            </li>
            <li>
              <button type="button" @click="chooseSum(250)">250&nbsp;₽</button>
            </li>
            <li>
              <button type="button" @click="chooseSum(500)">500&nbsp;₽</button>
            </li>
            <li>
              <button type="button" @click="chooseSum(1000)">1000&nbsp;₽</button>
            </li>
            <li>
              <input @input="inputSum" id="donate" type="text" v-model.trim="form.sum" :class="{error: sumIsNotValid}"
                     placeholder="Введите сумму" maxlength="10">
              <span v-if="sumIsNotValid" class="form__error__text">Выберите или введите сумму</span>
            </li>
          </ul>

          <div class="inputs">
            <div class="inputs__item input__name">
              <input @input="fioIsNotValid = false" type="text" v-model.trim="form.fio"
                     :class="{ error: fioIsNotValid }" class="input" placeholder="ФИО" maxlength="255">
              <span v-if="fioIsNotValid" class="form__error__text">Введите ФИО</span>
            </div>
            <div class="inputs__item input__phone">
              <input @input="phoneIsNotValid = false" type="text" v-model="form.phone"
                     :class="{ error: phoneIsNotValid }" class="input" placeholder="Номер телефона"
                     v-mask="'+7 (###) ###-##-##'">
              <span v-if="phoneIsNotValid" class="form__error__text">Введите номер телефона</span>
            </div>
          </div>

          <label class="donate__regular checkbox__label" for="regdonate">
            <input v-model="form.subscription" id="regdonate" type="checkbox">
            <span class="fake_checkbox">
              <CheckboxIcon/>
            </span>
            <span>Жертвовать ежемесячно</span>
          </label>

          <button v-if="!loading" type="submit" class="donate__btn btn">
            <HeartIcon/>
            <span>Пожертвовать</span>
          </button>
          <button v-else type="button" class="donate__btn btn">
            <Loader/>
          </button>
        </form>
        <button type="button" @click="$store.state.showCancelSubscrModal = true" class="cancel__subscr">Отменить подписку</button>
      </div>

      <!--Подключение библиотеки-->
      <!--        <script src="https://yookassa.ru/checkout-widget/v1/checkout-widget.js"></script>-->
      <!--HTML-элемент, в котором будет отображаться платежная форма-->
      <!--        <div id="payment-form" ref="widgetForm"></div>-->
    </div>
    <CancelSubscription/>
  </section>
</template>

<script>
import HeartIcon from 'components/svg/Heart.vue'
import CheckboxIcon from 'components/svg/CheckboxIcon.vue'
import Loader from 'components/Loader.vue'
import CancelSubscription from 'components/modals/CancelSubscription.vue'

export default {
  name: 'Donate',
  data () {
    return {
      form: {
        fio: '',
        phone: '',
        sum: '',
        // email: '',
        subscription: false
      },
      loading: false,
      fioIsNotValid: false,
      phoneIsNotValid: false,
      sumIsNotValid: false
    }
  },
  methods: {
    inputSum () {
      this.form.sum = this.form.sum.replace(/[^\d]/g, '')
      this.sumIsNotValid = false
    },
    chooseSum (sum) {
      this.form.sum = sum
      this.sumIsNotValid = false
      document.querySelector('#donate').style.color = '#44AD4E'
      document.querySelector('#donate').style.border = '1px solid #44AD4E'
    },
    submitDonateForm () {
      this.sumIsNotValid = Number(this.form.sum) <= 0
      this.fioIsNotValid = !this.form.fio
      this.phoneIsNotValid = this.form.phone.length !== 18

      if (this.sumIsNotValid || this.fioIsNotValid || this.phoneIsNotValid) {
        return 0
      }

      this.loading = true

      this.$store.dispatch('TEST_DONATE', {
        fio: this.form.fio,
        number: this.form.phone,
        sum: Number(this.form.sum),
        subscription: this.form.subscription ? true : null
      })
        .then(response => {
          this.loading = false
          this.$store.state.testDonateResponse = response.data
          this.$router.push({ name: 'confirmpay' })

          // if (response.data.url) {
          //   location.href = response.data.url
          // }
        })
        .catch(err => {
          this.loading = false
          console.error(err)
        })
    }
  },
  components: {
    HeartIcon,
    CheckboxIcon,
    Loader,
    CancelSubscription
  }
}
</script>