<template>
  <section class="leads section" v-if="leads && leads.length">
    <div class="container">
      <h2 class="leads__title">Представители фонда</h2>
      <div class="leads__wrapper">
        <swiper ref="mySwiper" :options="swiperOptions">

          <swiper-slide v-for="(item, index) in leads" :key="index">
            <div class="leads__item">
              <div class="leads__item__img">
                <img :src="url + item.head_img.img.url" alt="">
              </div>

              <div class="leads__item__info">
                <div class="leads__item__post">
                  {{ item.post }}
                </div>
                <div class="leads__item__name">
                  {{ item.fio }}
                </div>
                <a href="#" class="leads__item__contacts">
                  Контакты и описание
                </a>
              </div>
            </div>
          </swiper-slide>
        </swiper>

        <button v-if="leads.length >= 3" class="swiper__controls__left" type="button" @click="swiper.slidePrev()">
          <ArrowLeft/>
        </button>

        <button v-if="leads.length >= 3" class="swiper__controls__right" type="button" @click="swiper.slideNext()">
          <ArrowRight/>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ArrowLeft from 'components/svg/ArrowLeft.vue'
import ArrowRight from 'components/svg/ArrowRight.vue'

export default {
  name: 'Leads',
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        breakpoints: {
          340: {
            slidesPerView: 1.2
          },
          400: {
            slidesPerView: 1.4
          },
          500: {
            slidesPerView: 1.6
          },
          620: {
            slidesPerView: 2
          },
          890: {
            slidesPerView: 2.5
          },
          1097: {
            slidesPerView: 3
          }
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    leads: function () {
      return this.$store.state.home_page?.leads
    },
    url: function () {
      return this.$store.state.api
    }
  },
  mounted () {
    if (this.leads.length) {
      this.swiper.slideTo(3, 1000, false)
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight
  }
}
</script>
