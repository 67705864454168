<template>
  <i>
    <svg width="25" height="25" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.67047 4.2879L2.45833 4.07577L2.2462 4.2879L1.66287 4.87123L1.45074 5.08336L1.66287 5.2955L3.9962 7.62883L4.20337 7.83599L4.41538 7.63379L8.91539 3.3421L9.13769 3.13009L8.92048 2.91287L8.29548 2.28787L8.08679 2.07918L7.87472 2.28442L4.21178 5.82922L2.67047 4.2879Z" fill="white" stroke="white" stroke-width="0.6"/>
    </svg>
  </i>

</template>

<script>
export default {
  name: 'CheckboxIcon'
}
</script>
