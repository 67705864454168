<template>
  <main class="main">
    <WelcomeComponent/>
    <AboutChildren/>
    <Donate/>
    <Leads/>
    <Partners/>
    <VideoModal/>
  </main>
</template>

<script>
import WelcomeComponent from '@/views/home/components/Welcome.vue'
import AboutChildren from '@/views/home/components/AboutChildren.vue'
import Donate from '@/views/home/components/Donate.vue'
import Leads from '@/views/home/components/Leads.vue'
import Partners from '@/views/home/components/Partners.vue'
import VideoModal from '@/views/home/components/VideoModal.vue'

export default {
  async asyncData ({ store }) {
    await store.dispatch('GET_HOME_PAGE')
  },
  name: 'HomePage',
  computed: {
    nav () {
      return this.$store.state.navigators.find(item => item.link === this.$route.path)
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      this.nav.meta_title,
      this.nav.meta_keywords,
      this.nav.meta_description,
      'Mavadda',
      '',
      'Mavadda'
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    WelcomeComponent,
    AboutChildren,
    Donate,
    Leads,
    Partners,
    VideoModal
  }
}
</script>
