<template>
  <section class="welcome welcome__home">
    <div class="container">
      <div class="welcome__inner">
        <h1 class="welcome__title">
          Благотворительный фонд «МАВАДДА»
        </h1>
        <button @click="$store.state.showVideoModal = true" class="welcome__video__link">Презентационный ролик о фонде</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WelcomeComponent'
}
</script>
