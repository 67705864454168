<template>
  <i class="heart__icon">
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M42.8101 12L39.2101 8.5C37.6101 7.6 35.8101 7 33.8101 7C29.6101 7 26.0101 9.5 24.3101 13C22.6101 9.4 19.0101 7 14.8101 7C9.01006 7 4.31006 11.7 4.31006 17.5C4.31006 20.5 5.51006 23.1 7.51006 25L24.3101 42L41.4101 24.7C43.2101 22.9 44.3101 20.3 44.3101 17.5C44.3101 15.5 43.7101 13.6 42.8101 12Z" fill="#FF3D00"/>
      <path d="M39.2101 8.5L24.3101 23.5L17.1101 16.3C16.1101 15.3 14.5101 15.3 13.6101 16.3C12.6101 17.3 12.6101 18.9 13.6101 19.8L24.3101 30.5L42.8101 12C41.9101 10.6 40.7101 9.4 39.2101 8.5Z" fill="white"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'HeartIcon'
}
</script>
