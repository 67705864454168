<template>
  <i class="arrow__right">
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1282 8.70711C22.5188 8.31658 22.5188 7.68342 22.1282 7.29289L15.7643 0.928932C15.3738 0.538408 14.7406 0.538408 14.3501 0.928932C13.9595 1.31946 13.9595 1.95262 14.3501 2.34315L20.0069 8L14.3501 13.6569C13.9595 14.0474 13.9595 14.6805 14.3501 15.0711C14.7406 15.4616 15.3738 15.4616 15.7643 15.0711L22.1282 8.70711ZM0.476685 9H21.4211V7H0.476685V9Z" fill="#B4B4B4"/>
    </svg>
  </i>
</template>

<script>
export default {
  name: 'ArrowRightIcon'
}
</script>
