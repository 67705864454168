<template>
  <div v-if="$store.state.showVideoModal && video_url" class="modal__wrapper">
    <div @click="closeModal" class="modal__back">
    </div>

    <section class="container modal__video__container">
      <div class="form__container modal__inner modal__video__inner">
        <div v-if="video_url.value" class="modal__video__wrapper">
        <h3 class="modal__video__title">{{ video_url.title }}</h3>
        <iframe class="modal__video" width="1046" height="490" :src="video_url.value" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>

        <div v-else>
          Видео не найдено :(
        </div>

        <a @click="closeModal" class="modal__close__btn">
          <CloseBtn/>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import CloseBtn from 'components/svg/Close.vue'

export default {
  name: 'CallMe',
  methods: {
    closeModal () {
      this.$store.state.showVideoModal = false
    }
  },
  computed: {
    video_url: function () {
      return this.$store.state.home_page?.page_texts?.video_url
    }
  },
  components: {
    CloseBtn
  }
}
</script>
