<template>
  <div class="partners" v-if="partners && partners.length">
    <div class="container">
      <h2 class="partners__title">Партнеры</h2>
      <div class="partners__wrapper">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="(item, index) in partners" :key="index">
            <div class="partners__item">
              <a href="#">
                <img :src="url + item.head_img.img.url" alt="">
              </a>
            </div>
          </swiper-slide>
        </swiper>

        <button v-if="partners.length >= 3" class="swiper__controls__left" type="button" @click="swiper.slidePrev()">
          <ArrowLeft/>
        </button>

        <button v-if="partners.length >= 3" class="swiper__controls__right" type="button" @click="swiper.slideNext()">
          <ArrowRight/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ArrowLeft from 'components/svg/ArrowLeft.vue'
import ArrowRight from 'components/svg/ArrowRight.vue'

export default {
  name: 'Partners',
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        breakpoints: {
          340: {
            slidesPerView: 1.2
          },
          400: {
            slidesPerView: 1.4
          },
          500: {
            slidesPerView: 1.6
          },
          620: {
            slidesPerView: 2
          },
          890: {
            slidesPerView: 2.5
          },
          1097: {
            slidesPerView: 3
          }
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    partners: function () {
      return this.$store.state.home_page?.partners
    },
    url: function () {
      return this.$store.state.api
    }
  },
  mounted () {
    if (this.partners.length) {
      this.swiper.slideTo(3, 1000, false)
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    ArrowLeft,
    ArrowRight
  }
}
</script>
